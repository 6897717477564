
import Vue from "vue";
import spinner from "@/store/modules/spinnerModule";

export default Vue.extend({
  name: "SpinnerComponent",
  computed: {
    spinnerState(): boolean {
      return spinner.showLoader;
    },
  },
});
